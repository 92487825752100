<template>
    <div>
        <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
            <ValidationObserver ref="formModalValidate">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('start_date')+' / '+$t('end_date')">
                                <select-date v-model="datatable.queryParams.filter.date"
                                             :validation-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider name="time" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('start_time')+' / '+$t('end_time')">
                                <time-picker v-model="datatable.queryParams.filter.time"
                                             :minute-increment="5"
                                             :validate-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider name="campus_id" v-slot="{valid, errors}">
                            <b-form-group :label="$t('campus')">
                                <campus-selectbox v-model="datatable.queryParams.filter.campus_id"
                                                  :validate-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider name="building_id" v-slot="{valid, errors}">
                            <b-form-group :label="$t('building')">
                                <building-selectbox v-model="datatable.queryParams.filter.building_id"
                                                    :campus_id="datatable.queryParams.filter.campus_id"
                                                    :validate-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6">
                        <ValidationProvider name="classroom_id" v-slot="{valid, errors}">
                            <b-form-group :label="$t('classroom')">
                                <classroom-selectbox
                                    v-model="datatable.queryParams.filter.classroom_id"
                                    :building_id="datatable.queryParams.filter.building_id"
                                    :validate-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                </b-row>
            </ValidationObserver>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
        />
    </div>
</template>
<script>
// Component
import DatatableFilter from '@/components/datatable/DatatableFilter'
import Datatable from '@/components/datatable/Datatable'
import SelectDate from '@/components/interactive-fields/SelectDate'
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox.vue";
import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox'
import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox'
import TimePicker from '@/components/elements/TimePicker'
// Services

import ReservationService from '@/services/ReservationService'
// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import qs from 'qs'

export default {
    components: {
        ClassroomSelectbox,
        ValidationProvider,
        ValidationObserver,

        BuildingSelectbox,
        CampusSelectbox,
        DatatableFilter,
        Datatable,
        SelectDate,
        TimePicker
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('reservation_add'),
                                class: 'ri-add-line',
                                permission: 'reservation_store',
                                hidden: false,
                                callback: (row) => {
                                    this.$emit('createFormShow', {
                                        campus_id: row.campus_id,
                                        building_id: row.building_id,
                                        classroom_id: row.id,
                                        date: this.datatable.queryParams.filter.date,
                                        time: this.datatable.queryParams.filter.time
                                    })
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase(this.spaceToBR(this.$t('campus'))),
                        field: 'campus_name',
                        sortable: false,
                        formatFn: (value, row) => {
                            return row.campus_name
                        }
                    },
                    {
                        label: this.toUpperCase(this.$t('building')),
                        field: 'building_name',
                        sortable: false,
                        formatFn: (value, row) => {
                            return row.building_name
                        }
                    },
                    {
                        label: this.toUpperCase(this.$t('classroom')),
                        field: 'name',
                        sortable: false,
                        formatFn: (value, row) => {
                            return row.name
                        }
                    },
                    {
                        label: this.toUpperCase(this.$t('capacity')),
                        field: 'capacity',
                        sortable: false,
                        thClass: 'text-center',
                        tdClass: 'text-center'
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        date: null,
                        time: null,
                        campus_id: null,
                        classroom_id: null,
                        building_id: null,
                    },
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                date: null,
                time: null
            }
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async getRows() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                // Filters
                let filters = {
                    ...this.datatable.queryParams.filter
                }

                // Modify Filters
                let modifyFilters = {
                    date: filters.date,

                }

                // Time
                if (filters.time) {
                    modifyFilters.start_time = filters.time.start
                    modifyFilters.end_time = filters.time.end
                }

                const config = {
                    params: {
                        filter: {
                            campus_id: filters.campus_id,
                            building_id: filters.building_id,
                            id: filters.classroom_id
                        },
                        ...modifyFilters,
                        sort: this.datatable.queryParams.sort,
                        page: this.datatable.queryParams.page,
                        limit: this.datatable.queryParams.limit
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return ReservationService.emptyClassrooms(config)
                    .then((response) => {
                        this.datatable.rows = response.data.data;
                        this.datatable.total = response.data.pagination.total;
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    })
                    .finally(() => {
                        this.datatable.isLoading = false;
                    });
            }
        }
    }
}
</script>
