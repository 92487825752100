<template>
    <div>
        <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
            <ValidationObserver ref="formModalValidate">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox v-model="datatable.queryParams.filter.campus_id"/>
                        </b-form-group>
                        <b-form-group :label="$t('building')">
                            <building-selectbox v-model="datatable.queryParams.filter.building_id"
                                                :campus_id="datatable.queryParams.filter.campus_id"
                            />
                        </b-form-group>
                        <b-form-group :label="$t('classroom')">
                            <classroom-selectbox v-model="datatable.queryParams.filter.classroom_id"
                                                 :building_id="datatable.queryParams.filter.building_id"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('start_date')+' / '+$t('end_date')">
                            <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                <v-date-picker v-model="datatable.queryParams.filter.date"
                                               is-range
                                               :locale="'en'"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center" :class="errors[0] ? 'box-border-color':''">
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 mr-1">
                                                    <input :value="inputValue.start"
                                                           v-on="inputEvents.start"
                                                           :placeholder="$t('start_date')"
                                                           class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 ml-1">
                                                    <input :value="inputValue.end"
                                                           v-on="inputEvents.end"
                                                           :placeholder="$t('end_date')"
                                                           class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </template>
                                </v-date-picker>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group :label="$t('start_time')+' / '+$t('end_time')">
                            <time-picker v-model="datatable.queryParams.filter.time"
                                         :minute-increment="5"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('reservation_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.type"
                                                 code="reservation_types"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template v-slot:json-excel>
                <div class="mb-2 mr-2 mr-xl-0">
                    <json-excel :data="datatable.rows"
                            :fields="excelFields"
                            type="xls"
                            :name="`${$t('reservations')}.xls`"
                            >
                    <b-button variant="success" block>
                         {{ $t('excel') }}
                    </b-button>
                </json-excel>
                        </div>
            </template>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
        />
        <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('reservation_update')">
            <template v-slot:CommonModalTitle>
                {{ $t('edit') }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :formId="formId"
                             @updateFormSuccess="updateFormSuccess"
                             v-if="formProcess=='update'"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
    import JsonExcel from "vue-json-excel";
    // Component
    import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox'
    import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox'
    import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox'
    import CommonModal from '@/components/elements/CommonModal'
    import Datatable from '@/components/datatable/Datatable'
    import DatatableFilter from '@/components/datatable/DatatableFilter'
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
    import TimePicker from '@/components/elements/TimePicker'

    // Pages
    import UpdateForm from '@/modules/reservation/pages/reservationSystem/UpdateForm'

    // Services
    import ReservationService from '@/services/ReservationService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import qs from 'qs'

    export default {
        props: {
            rsRefresh: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            JsonExcel,
            BuildingSelectbox,
            CampusSelectbox,
            ClassroomSelectbox,
            CommonModal,
            Datatable,
            DatatableFilter,
            ParameterSelectbox,
            TimePicker,

            UpdateForm
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'reservation_update',
                                    hidden: false,
                                    callback: (row) => {
                                        this.updateFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-2-line',
                                    permission: 'reservation_delete',
                                    hidden: false,
                                    callback: (row) => {
                                        this.delete(row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.toUpperCase(this.$t('date')),
                            field: 'date',
                            sortable: true,
                            formatFn: (value) => {
                                return value ? this.toLocaleDate(value) : '-';
                            }
                        },
                        {
                            label: this.$t('day'),
                            field: 'day',
                            sortable: true
                        },
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('start_time'))),
                            field: 'start_time',
                            sortable: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('end_time'))),
                            field: 'end_time',
                            sortable: false,
                            tdClass: 'text-center',
                            thClass: 'text-center'
                        },
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('campus'))),
                            field: 'campus',
                            sortable: false,
                            formatFn: (value) => {
                                return value ? value.name : '-';
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('building')),
                            field: 'id',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.classroom && row.classroom.building ? row.classroom.building.name : '-';
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('classroom')),
                            field: 'classroom',
                            sortable: false,
                            formatFn: (value, row) => {
                                return value ? value.name : '-';
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('event_type')),
                            field: 'type',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.type_text;
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('event_owner')),
                            field: 'instructor_name',
                            sortable: false
                        },
                        {
                            label: this.toUpperCase(this.$t('explanation')),
                            field: 'explanation',
                            sortable: false
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                            campus_id: null,
                            classroom_id: null,
                            date: {start: null, end: null},
                            //date: {start: this.getDate(), end: this.getDate()},
                            time: null,
                            type: null
                        },
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },
                formLoading: false,
                formId: null,
                formProcess: null
            }
        },
        computed: {
            excelFields(){
                const columns = [...this.datatable.columns]
                columns.shift()
                const fields = {}
                columns.forEach(item =>  {
                    if(item.field === "campus" || item.field=== "classroom" ) {
                        fields[item.label.replace("<BR>"," ")] = `${item.field}.name`
                    }
                    else {
                        fields[item.label.replace("<BR>"," ")] = item.field
                    }

                })
                return fields
            }
        },
        watch: {
            rsRefresh: {
                handler: function(val) {
                    if(val){
                        this.getRows()
                    }
                }
            }
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    campus_id: null,
                    classroom_id: null,
                    date: {},
                    time: null,
                    type: null
                }
                this.getRows(true);
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            async getRows(filterClear) {
                const isValid = await this.$refs.formModalValidate.validate();

                if (isValid) {
                    // Filters
                    let filters = {...this.datatable.queryParams.filter}

                    // Modify Filters
                    let modifyFilters = {}

                    // Date
                    if (filters.date && filters.date.start && filters.date.end) {
                        this.datatable.showTable = true;
                        this.datatable.isLoading = true;

                        if (filters.date.start == filters.date.end) {
                            modifyFilters.date = filters.date.start;
                        }
                        else {
                            modifyFilters.date = filters.date.start + ',' + filters.date.end;
                        }
                    }
                    else {
                        this.datatable.showTable = false;
                        this.datatable.isLoading = false;

                        this.datatable.queryParams.filter.date = filterClear ? {} : null

                        return
                    }

                    // Campus Id
                    if (filters.campus_id) {
                        modifyFilters.campus_id = filters.campus_id
                    }

                    // Classroom Id
                    if (filters.classroom_id) {
                        modifyFilters.classroom_id = filters.classroom_id
                    }

                    // Time
                    if (filters.time) {
                        modifyFilters.start_time = filters.time.start
                        modifyFilters.end_time = filters.time.end
                    }

                    // Time
                    if (filters.type) {
                        modifyFilters.type = filters.type
                    }

                    const config = {
                        params: {
                            sort: this.datatable.queryParams.sort,
                            page: this.datatable.queryParams.page,
                            limit: this.datatable.queryParams.limit,
                            filter: {
                                ...modifyFilters
                            }
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    };

                    return ReservationService.getAll(config)
                                             .then((response) => {
                                                 this.datatable.rows = response.data.data;
                                                 this.datatable.total = response.data.pagination.total;
                                             })
                                             .catch((e) => {
                                                 this.showErrors(e);
                                             })
                                             .finally(() => {
                                                 this.datatable.isLoading = false;
                                             });
                }
            },


            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            delete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            ReservationService.del(id)
                                          .then(response => {
                                              this.$toast.success(this.$t('api.' + response.data.message));
                                              this.getRows();
                                              this.formClear();
                                          })
                                          .catch(error => {
                                              this.$toast.error(this.$t('api.' + error.data.message));
                                          });
                        }
                    })
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.updateFormModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            }
        }
    }
</script>
